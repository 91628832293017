<template>
  <div>
    <div class="nav">
      <div class="button" @click="dialogFormVisible = true">
        <div class="icon">
         <i class="el-icon-upload"></i>
      </div>
      <span>上架新药</span>

      </div>
      <el-dialog :before-close="handleClose" title="上架新药" :visible.sync="dialogFormVisible" :modal-append-to-body=false>
        <el-form status-icon ref="form" :rules="rules" :model="form" size="small" label-width="80px">
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name"   show-word-limit  maxlength="20" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-select v-model="form.type" placeholder="请选择药品类型">
              <el-option v-for="item in options" :label="item.type" :value="item.type"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="数量" prop="number">
            <el-input v-model.number="form.number"    placeholder="库存数量"></el-input>
          </el-form-item>
          <el-form-item label="单价" prop="price">
            <el-input v-model.number="form.price" placeholder="请输入单价"></el-input>
          </el-form-item>

          <el-form-item label="药品规格" prop="specification">
            <el-input  v-model="form.specification"   show-word-limit maxlength="30" placeholder="请输入规格"></el-input>
          </el-form-item>
          <el-form-item label="药品简介" prop="information">
            <el-input type="textarea"  v-model="form.information"   show-word-limit maxlength="500" placeholder="请输入介绍"></el-input>
          </el-form-item>
          <el-form-item label="积分商品">
            <el-select v-model="form.Integral_goods" placeholder="是否为积分商品">
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>


          <el-form-item label="药品照片" prop="fileList">
            <el-upload ref="upload" class="upload-demo" accept=".jpg,.png" :auto-upload="false" :multiple="false"
              :limit="1" action="" :http-request="httpRequest" list-type="picture">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
            </el-upload>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="submitForm">上 架</el-button>
        </div>
      </el-dialog>

    </div>

    <el-table stripe height="520px" v-loading="loading" element-loading-text="拼命加载中" size="small" :data="search ? tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase())) :
        showTableData" style="width: 100%">
      <el-table-column label="ID" prop="id">
      </el-table-column>

      <el-table-column label="图片">
        <template slot-scope="scope">
          <el-popover placement="top-start" title="" trigger="hover">
            <img :src="imgurl + scope.row.imgurl" alt="" style="width: 150px;height: 150px">
            <img slot="reference" :src="imgurl + scope.row.imgurl" style="width: 50px;height: 30px">
          </el-popover>
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="name">
      </el-table-column>
      <el-table-column label="作用" prop="type">
      </el-table-column>
      <el-table-column label="规格" prop="specification">
      </el-table-column>
      <el-table-column label="单价" prop="price">
      </el-table-column>
      <el-table-column label="库存" prop="number">
      </el-table-column>
      <el-table-column label="积分商品" prop="Integral_goods">
        <template slot-scope="scope">
          <i v-if="scope.row.Integral_goods == '1'" class="el-icon-check"></i>
          <i v-else class="el-icon-close"></i>
        </template>
      </el-table-column>

      <el-table-column align="right" width="150">
        <template slot="header" slot-scope="scope">
          <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
        </template>
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog title="编辑" width="30%" :visible.sync="dialogFormVisible2">
      <el-form :model="form2" size="small" label-position="left">
        <el-form-item label="单价" :label-width="formLabelWidth">
          <el-input v-model="form2.price" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="库存" :label-width="formLabelWidth">
          <el-input v-model="form2.number" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="简介" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="form2.information" show-word-limit maxlength="500" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="规格" :label-width="formLabelWidth">
          <el-input v-model="form2.specification"   show-word-limit maxlength="30" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="积分商品">
          <el-select v-model="form2.Integral_goods" placeholder="是否为积分商品">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <div v-if="!search" class="block" style="display: flex;justify-content: center;">
      <el-pagination @current-change="handleCurrentChange" :page-size="numberPerPage" layout="prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { addGoods, getDataG, upGoods, deGoods, getType } from '@/api'
import { URL} from '@/util/request'
export default {
  data() {
    return {
      headurl: URL,
      imgurl: URL+'media/',
      loading: true,
      dialogTableVisible: false,
      dialogFormVisible: false,
      showTableData: [],
      numberPerPage: 10,
      total: 0,
      tableData: [],
      form: {
        name: '',
        type: '',
        number: '',
        information: '',
        price: '',
        fileList: [],
        Integral_goods: '',
        specification:''
      },
      form2: {
        number: '',
        price: '',
        id: '',
        Integral_goods: false,
        information: '',
        specification:''
      },

      formLabelWidth: '120px',
      dialogFormVisible2: false,
      search: '',
      options: [],
      rules: {
        name: [
          { required: true, message: '请输入药品名称', trigger: 'blur' },
          { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择药品类型', trigger: 'change' },
        ],
        price: [
          { required: true, message: '请输入单价', trigger: 'blur' },
          { type: 'number', message: '单价必须为数字值' }
        ],
        number: [
          { required: true, message: '请填写库存', trigger: 'blur' },
          { type: 'number', message: '库存必须为数字值' }
        ],
        fileList: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ],
        information: [
          { required: true, message: '请输入药品描述', trigger: 'blur' },
          { min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' }
        ],
        specification: [
          { required: true, message: '请输入药品规格', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 30 个字符', trigger: 'blur' }
        ],
        Integral_goods: [
            { required: true, message: '是否为积分商品', trigger: 'change' }
          ],
      }
    };
  },
  inject: ["refresh"],
  methods: {
    submitForm() {
      this.$refs.upload.submit()
      this.$refs.form.validate((valid) => {
        if (valid) {
          const formData = new FormData();
          for (let key in this.form) {
            formData.append(key, this.form[key])
          }
          addGoods(formData).then((data) => {
            if (data.data.status == 200) {
              this.dialogFormVisible = false
              this.resetForm()
              this.$refs.upload.clearFiles()
              this.open2(data.data.msg)
              this.refresh()
            }
            else {
              alert('请求失败')
            }
          })
        } else {
          this.open4()
          return false;
        }
      });
    },

    handleEdit(index, row) {
      this.dialogFormVisible2 = true
      this.form2.price = row.price
      this.form2.number = row.number
      this.form2.id = row.id
      this.form2.Integral_goods = row.Integral_goods
      this.form2.information = row.information
      this.form2.specification = row.specification
    },
    handleCurrentChange(currentindex) {
      this.showTableData = this.tableData.slice((currentindex - 1) * this.numberPerPage, currentindex * this.numberPerPage)
    },

    open(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deGoods(row.id).then((data) => {
          if (data.data.status == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.refresh()
          } else {
            alert('请求失败')
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },


    handleDelete(index, row) {
      this.open(row)
    },

    onSubmit() {
      this.dialogFormVisible2 = false
      const formData = new FormData();
      for (let key in this.form2) {
        formData.append(key, this.form2[key])
      }
      upGoods(formData).then((data) => {
        if (data.data.status == 200) {
          this.$message({
            message: data.data.msg,
            type: 'success'
          });
          this.refresh()
        }
        else
          alert('请求失败')
      })

    },


    resetForm() {
      this.$refs.form.resetFields();
    },

    handleClose() {
      this.resetForm()
      this.dialogFormVisible = false
    },

    httpRequest(param) {
      //把上传的文件赋值给data属性的file
      this.form.fileList = param.file;
    },

    open2(msg) {
      this.$message({
        message: msg,
        type: 'success'
      });
    },
    open4() {
      this.$notify.error({
        title: '错误',
        message: '请确认表单是否填写正确'
      });
    }

  },
  mounted() {
    getDataG().then((data) => {
      if (data.data.status == 200) {
        this.tableData = data.data.data
        this.total = data.data.data.length ? data.data.data.length : 0
        this.showTableData = data.data.data.slice(0, this.numberPerPage);
        this.loading = false
      } else {
        this.open4(data.data.msg)
      }
    })
    getType().then((data => {
      if (data.data.status == 200) {
        this.options = data.data.data
      } else {
        this.open4(data.data.msg)
      }
    }))
  }
}


</script >
<style lang="less" scoped >
@keyframes bgAnimation{
  0%{
    background-position:0 50% ;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0 50%;
  }
}
@keyframes move{
  0%{
    width: 0px;
  }

  100%{
    width: 100px;
  }
}
.nav {
  width: 100%;
  height: 42px;
  padding: 2px 10px 2px 10px ;
  .button {
    height: 40px;
    width: 40px;
    display: inline-block;
    float: left;
    overflow: hidden;
    background: #409EFF;
    border-radius:50px;
    cursor: pointer;
    transition: all 0.3s ease-out;
    line-height: 40px;
    .icon{
      display: inline-block;
      height: 40px;
      width: 40px;
      text-align: center;
      border-radius: 50px;
      box-sizing:border-box ;
      transition: all 0.3s ease-out;
      color: #fff;
    }
  }
  .button:hover{
    width: 9%;
  //   background: linear-gradient(125deg, #2c3e50, #27ae60,#8e44ad,#2980b9,#e74c3c);
  // animation: bgAnimation 15s linear infinite; 
  // background-size: 500%;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  }
  .button:hover .icon{
    background-color: #F56C6C;

  }
  .button span{
    color: #fff;
  }
}

/deep/ .cell {
  height: 45px;
  overflow-y: auto;
}
</style>