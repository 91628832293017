<template>
  <div class="box ">
    <div class="weather" v-if="identity=='医生'">
      <div class="weather-icon">
        <v-mini-weather >
          <template #default="{ icon }">
            <!--插入图标-->
            <v-mini-weather-icon :icon="icon"></v-mini-weather-icon>
            <!--DIY内容-->
          </template>
        </v-mini-weather>
      </div>

    </div>
    <div class="front" v-if="identity=='管理员'">校医院微信小程序后台管理系统</div>
    <div class="front" v-else>
      <div class="weather-text">
        <v-mini-weather>
          <template #default="{ weather }">
            <span>{{ weather.cityname }}/{{ weather.weather }}/{{ weather.temp }}°C</span>
          </template>
        </v-mini-weather>
      </div>
    </div>
    <div class="bottom">
      <i class="el-icon-loading"></i>
      <el-breadcrumb color="#fff" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="item in tabList" :to="{ path: item.path }">{{ item.label }}</el-breadcrumb-item>
      </el-breadcrumb>

      <el-dropdown @command="handleCommond">
        <span class="el-dropdown-link">
          <i class="el-icon-user-solid" color="#fff"></i>
        </span>
        <el-dropdown-menu v-if="identity=='管理员'" slot="dropdown">
          <!-- <el-dropdown-item command="addAdmin">新建管理员</el-dropdown-item> -->
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
        <el-dropdown-menu v-else slot="dropdown">
          <!-- <el-dropdown-item command="me">个人中心</el-dropdown-item> -->
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <!-- <el-drawer title="新建管理员" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
    <el-form :model="adminForm" :rules="adminrules" status-icon ref="admin" label-width="100px" class="demo-ruleForm">
      <el-form-item label="账号" prop="account">
        <el-input :maxlength="10" v-model="adminForm.account"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pass">
        <el-input :maxlength="20" type="password" v-model="adminForm.pass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input :maxlength="20" type="password" v-model="adminForm.checkPass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitAdmin">提交</el-button>
        <el-button @click="resetForm('admin')">重置</el-button>
      </el-form-item>
    </el-form>
    </el-drawer> -->
    </div>

  </div>
</template>

<script>



import { addAdmin } from '@/api'
import Cookie from 'js-cookie'
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.adminForm.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      adminForm: {
        pass: '',
        checkPass: '',
        account: '',
      },
      drawer: false,
      direction: 'rtl',
      adminrules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { max: 10, message: '账号最大长度为10位', trigger: 'blur' }
        ],
        pass: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在6到20之间', trigger: 'blur' }
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在6到20之间', trigger: 'blur' }
        ],

      }


    }
  },
  methods: {
    handleCommond(command) {
      if (command == 'logout') {
        Cookie.remove('token')
        Cookie.remove('menu')
        Cookie.remove('doctor')
        Cookie.remove('identity')
        this.$router.push('/login')
      }
      if (command == 'addAdmin') {
        this.drawer = true

      }
      if (command == 'me') {
        this.$router.push('/me')
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    submitAdmin() {
      this.$refs.admin.validate((valid) => {
        if (valid) {
          addAdmin(this.adminForm).then((data) => {
            if (data.data.status == 200) {
              this.handleClose()
              this.open2()
            }
            else {
              this.$message({
                showClose: true,
                message: data.data.msg,
                type: 'error'
              });
            }
          })


        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    handleClose() {
      this.$refs.admin.resetFields()
      this.drawer = false
    },
    open2() {
      this.$message({
        message: '恭喜你，上架成功',
        type: 'success'
      });
    },




  },
  computed: {
    tabList() {
      return this.$store.state.tab.tabList
    },
    identity() {
      return Cookie.get('identity')
    }
  },


}


</script>


<style lang="less" scoped>
@keyframes bgAnimation {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}


i {
  color: #ffffff;
  font-size: 30px
}

i:hover {
  color: #F56C6C;
}


.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;

  img {
    background-color: #fff;
    border-radius: 50%;
  }
}

.el-icon-arrow-down {
  font-size: 12px;
}

/deep/ .el-breadcrumb__item .el-breadcrumb__inner {
  font-weight: normal !important;
  ;
}

.box {
  width: 100%;
  height: 60px;
  position: relative;
  transform-style: preserve-3d;
  transition: all .4s;
  box-sizing: border-box;
}

.box:hover {
  transform: rotateX(90deg);
}

.front,
bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.front {

  color: #fcfcfc;
  text-align: center;
  font-size: 15px;
  letter-spacing: 8px;
  z-index: 1;
  line-height: 60px;
  transform: rotateZ(30px);
  background: linear-gradient(220.55deg, #7CF7FF 0%, #4B73FF 100%);
  animation: bgAnimation 15s linear infinite;
  background-size: 500%;
}

.bottom {
  color: #fcfcfc;
  text-align: center;
  display: flex;
  justify-content: space-between;
  transform: translateY(30px) rotateX(-90deg);
  background-color: #409EFF;
  align-items: center;
  line-height: 60px;
  box-sizing: border-box;
  padding: 0 20px 0 20px;
}

/deep/ .el-breadcrumb__inner {
  color: #fff;
}

.weather-icon {
  position: absolute;
  z-index: 10;
  width: 60px;
  height: 100%;
}

.weather-text {
  height: 100%;
  text-align: center;
  line-height: 60px;

}
</style>