<template>
    <div  v-loading="loading" element-loading-text="拼命加载中">
        <el-tabs >
            <el-tab-pane style="max-width: 530px;overflow-y: auto;" label="历史公告">
                <div>
                    <el-collapse-transition>
                    <el-timeline>
                        <el-timeline-item v-for="(item, index) in noticeList" :key="item.datetime"
                            :timestamp=item.datetime placement="top">
                            <el-card class="box-card">
                                <div slot="header" class="clearfix">
                                    <span>{{ item.title }}</span>
                                    <div style="float: right;">
                                        <el-button size="mini" @click="edit(item)">编辑</el-button>
                                        <el-button size="mini" type="danger" @click="Delete(item.id)">删除</el-button>
                                    </div>
                                </div>
                                <div class="text item">
                                    {{ item.content }}
                                </div>
                            </el-card>
                        </el-timeline-item>
                    </el-timeline>
                </el-collapse-transition>


                    <el-dialog title="公告编辑" :visible.sync="dialogFormVisible">
                        <el-form :model="form" ref="form">
                            <el-form-item label="标题" :label-width="formLabelWidth">
                                <el-input v-model="form.title" maxlength="25" show-word-limit autocomplete="off"
                                    placeholder="请输入标题"></el-input>
                            </el-form-item>
                            <el-form-item label="内容" :label-width="formLabelWidth">
                                <el-input autosize type="textarea" maxlength="500" show-word-limit :rows="2"
                                    placeholder="请输入内容" v-model="form.content"></el-input>
                            </el-form-item>

                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button @click="close">取 消</el-button>
                            <el-button type="primary" @click="submitForm('edit')">确 定</el-button>
                        </div>
                    </el-dialog>
                </div>
                <el-empty v-if="noticeList.length == 0" description="空空如也·"></el-empty>
            </el-tab-pane>

            <el-tab-pane label="发布公告">
                <el-form :model="form" status-icon ref="form" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="标题" prop="title">
                        <el-input show-word-limit maxlength="25" v-model="form.title"></el-input>
                    </el-form-item>
                    <el-form-item label="内容" prop="content">
                        <el-input show-word-limit  maxlength="500"  type="textarea" v-model="form.content"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button  type="primary" @click="submitForm('add')">提交</el-button>
                        <el-button @click="resetForm()">重置</el-button>
                    </el-form-item>
                </el-form>





            </el-tab-pane>

        </el-tabs>


    </div>

</template>

<script>
import { getNotice, addNotice, deNotice, editNotice } from '@/api'
export default {
    data() {
        return {
            loading: true,
            dialogFormVisible: false,
            noticeList: [],
            rules: {
                title: [
                    { required: true, message: '请输入表体', trigger: 'blur' },
                    { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                ],
                content: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { min: 5, max: 100, message: '长度在 5 到 500 个字符', trigger: 'blur' }
                ],
            },
            form: {
                id: 0,
                title: '',
                content: ''
            },
            formLabelWidth: '120px'
        }
    },
    inject: ["refresh"],

    methods: {
        resetForm() {
            this.$refs.form.resetFields();
        },
        success(message) {
            this.$message({
                message: message,
                type: 'success'
            });
        },
        fail(msg) {
            this.$message.error(msg);
        },
        Delete(id) {
            this.$confirm('此操作将撤回该公告, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deNotice(id).then((data) => {
                    if (data.data.status == 200) {
                        this.$message({
                            type: 'success',
                            message: '撤回成功!'
                        });
                        this.refresh()
                    } else {
                        this.fail(data.data.msg)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        submitForm(type) {
            if (type == 'add') {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        addNotice(this.form).then((data) => {
                            if (data.data.status == 200) {
                                this.success('添加成功')
                                this.resetForm()
                                this.refresh()
                            } else {
                                this.fail(data.data.msg)
                            }
                        })
                    } else {
                        return false;
                    }
                });
            } else {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        editNotice(this.form).then((data) => {
                            if (data.data.status == 200) {
                                this.success('修改成功')
                                this.resetForm()
                                this.refresh()
                            } else {
                                this.fail(data.data.msg)
                            }
                        })
                    } else {
                        return false;
                    }
                });

            }
        },
        edit(item) {
            this.form.id = item.id
            this.form.title = item.title
            this.form.content = item.content
            this.dialogFormVisible = true
        },
        close() {
            this.dialogFormVisible = false
            this.resetForm()
        }

    },

    mounted() {
        getNotice().then((data) => {
            if (data.data.status == 200) {
                this.noticeList = data.data.data
                this.loading = false
            } else {
                this.fail(data.data.msg)
            }

        })
    }
}

</script>
<style lang="less" scoped>
/deep/ .el-collapse-item__content {
    padding-bottom: 0;
}
.el-tabs--border-card{
    background: none;
    border: none;
    box-shadow: none;
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.box-card {
    max-width: 480px;
}
</style>