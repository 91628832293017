import axios  from "axios";
import Cookie from 'js-cookie'
import router from '../router/index';
const URL = 'https://www.jnqsy.asia/api/'

const http = axios.create({
    baseURL:URL,
    timeout:10000,
})


http.interceptors.request.use(function (config) {
 
    // 在发送请求之前做些什么
    if (Cookie.get('token')) {
      config.headers.Authorization = Cookie.get('token')
      }
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加响应拦截器
http.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if(response.data.status==403){
      Cookie.remove('token')
      Cookie.remove('menu')
      Cookie.remove('doctor')
      Cookie.remove('admin')
      router.push('/login')
    }
  
    return response;
  }, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  });


export default http
export {
  URL
}


