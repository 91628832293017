<template>
<el-container>
  <el-header><common-header /></el-header>
  <el-container >
    <el-aside width="auto" ><common-aside/></el-aside>
    <el-main><router-view></router-view></el-main>
  </el-container>
</el-container>
</template>



<script>
import CommonAside from '../components/CommonAside.vue'
import CommonHeader from '@/components/CommonHeader.vue';
export default {
    data(){
        return {}
    },
    components:{
    CommonAside,
    CommonHeader
}
}

</script>
<style lang="less" >
.el-container{
  background-color: #f7f8fa;
  height: 100vh;
  font-family: "Hiragino Sans GB";

}
.el-main{
  padding: 10px 20px 10px 20px;
  margin:0 ;
}

</style>