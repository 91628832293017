<template>
    <div class="leftNav">
        <div class="switch" @click="tooggleCollapse">
            <div class="top"></div>
            <div class="identity" v-if="!isCollapse">
                {{ identity }}

            </div>
            <span class="button">
                <el-tooltip class="item" effect="dark" content="折叠" placement="right">
                    <i :class="!isCollapse ? 'el-icon-s-fold' :
            'el-icon-s-unfold'"></i>
                </el-tooltip>
            </span>
        </div>
        <el-menu class="el-menu-vertical-demo"  :collapse="isCollapse" active-text-color="#fff">

            <el-submenu v-for="(item, index) in menuData" v-if="item.children" :index="item.label">
                <template slot="title">
                    <i :class="item.icon"></i>
                    <span slot="title">{{ item.label }}</span>
                </template>
                <el-menu-item-group v-for="(subItem, index) in item.children" :key="subItem.path">
                    <el-menu-item @click="clickMenu(subItem)" :index="subItem.path"> {{ subItem.label }}</el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-menu-item @click="clickMenu(item)" v-else :index="item.path">
                <i :class="item.icon"></i>
                <span slot="title">{{ item.label }}</span>
            </el-menu-item>




        </el-menu>
    </div>

</template>
<style lang="less" scoped>
@keyframes bgAnimation {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

.leftNav {
    // height:100vh;
    background-color: #ffffff;
    box-shadow:5px 0px 8px -5px rgba(0, 0, 0, 0.2);

}


.switch {
    width: auto;
    text-align: center;
    height: 40px;
    line-height: 30px;
    color: #67c23a;
    background-color: #f0f9eb;

    .identity {
        display: inline-block;
        font-size: 13px;
    }

    .top {
        padding: 2px 0 2px 0;
        width: auto;
        height: 10px;
        box-sizing: border-box;
        background-color: #ffffff;

    }

    .button {
        display: inline-block;
        float: right;
        height: 30px;
        width: 30px;
        background-color: #cdcdcd;
    }

}

.switch:hover {

    color: #409EFF;
}




.el-menu-item {
    text-transform: uppercase;
    position: relative;
    outline: none;
    box-sizing: border-box;


}

.el-menu-item::after,
.el-menu-item::before {
    box-sizing: inherit;
    position: absolute;
    content: '';
    border: 2px solid transparent;
    width: 0;
    height: 0;

}
.el-menu-item::after {

bottom: 0;

right: 0;

}

.el-menu-item::before {

top: 0;

left: 0;

}

.el-menu-item:hover::before, .el-menu-item:hover::after {

width: 100%;

height: 100%;

}

.el-menu-item:hover::before {

border-top-color: #0080ff;

border-right-color: #0080ff;

transition: width 0.3s ease-out, height 0.3s ease-out 0.3s;

}

.el-menu-item:hover::after {

border-bottom-color: #0080ff;

border-left-color: #0080ff;

transition: border-color 0s ease-out 0.6s, width 0.3s ease-out 0.6s, height 0.3s ease-out 1s;


}



.el-menu-vertical-demo:not(.el-menu--collapse) {
    min-height: 400px;
    width: 200px;
}

.el-menu-item.is-active {
    background-color: #409EFF;
    animation: bgAnimation 8s linear infinite;
    background-size: 500%;
}
</style>

<script>
import Cookie from 'js-cookie'
export default {
    data() {
        return {
            isCollapse: false
        };
    },
    methods: {
        clickMenu(item) {
            if (this.$route.path != item.path && !(this.$route.path == '/orderForm' && (item.path == '/'))) {
                this.$router.push(item.path),
                    this.$store.commit('selectMenu', item)
            }
        },
        tooggleCollapse() {
            this.isCollapse = !this.isCollapse
        }

    },
    computed: {
        menuData() {
            return JSON.parse(Cookie.get('menu')) || this.$store.state.tab.menu
        },
        identity() {
            return Cookie.get('identity')

        }

    },


}
</script>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}
</style>