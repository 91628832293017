<template>
    <div >
        <el-table  size="small" v-loading="loading" element-loading-text="拼命加载中" height="580px" :data="search ? tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase())) :
            showTableData" style="width: 100%;">
            <el-table-column label="账号" prop="id">
            </el-table-column>
            <el-table-column label="头像">
                <template slot-scope="scope">
                    <el-popover placement="top-start" title="" trigger="hover">
                        <el-image slot="reference" fit="scale-down" :src="img_url + scope.row.face"
                            style="width: 50px;height: 30px;margin-left: -10px;"></el-image>
                        <img :src="img_url + scope.row.face" alt="" style="width: 150px;height: 150px">

                    </el-popover>
                    <span>{{ scope.row.title }}</span>
                </template>
            </el-table-column>
            <el-table-column label="用户名称" prop="name">
            </el-table-column>
            <el-table-column label="加密密码" prop="password">
            </el-table-column>
            <el-table-column label="注册时间"  prop="datetime">
            </el-table-column>
            <el-table-column align="right">
                <template slot="header" slot-scope="scope">
                    <el-input v-model="search" size="mini" placeholder="输入用户名称搜索" />
                </template>
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit"
                        circle></el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete"
                        @click="handleDelete(scope.$index, scope.row)" circle></el-button>
                </template>
            </el-table-column>
        </el-table>


        <el-dialog width="30%" :before-close="handleClose" title="用户信息" :visible.sync="dialogFormVisible">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="用户名称" :label-width="formLabelWidth" prop="name">
                    <el-input :maxlength="20" v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码(非加密)" :label-width="formLabelWidth" prop="password">
                    <el-input :maxlength="20" v-model="form.password" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>

        <div v-if="!search" style="display: flex;justify-content: center;" class="block">
            <el-pagination @current-change="handleCurrentChange" :page-size="numberPerPage" layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </div>


    </div>
</template>

<script>
import { getUsers, upUsers, deUser} from '@/api';
import { URL} from '@/util/request'
export default {
    data() {
        return {
            headurl: URL,
            img_url: URL+'media/',
            loading: true,
            dialogFormVisible: false,
            tableData: [],
            showTableData: [],
            numberPerPage: 10,
            total: 0,
            search: '',
            value: [],
            options: [],
            formLabelWidth: '120px',
            form: {
                id: '',
                name: '',
                password: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入用户名称', trigger: 'blur' },
                    { min: 2, max: 10, message: '长度在 2 到 10个字符', trigger: 'blur' }
                ]
            }
        }
    },
    inject: ["refresh"],
    methods: {
        handleEdit(index, row) {
            this.dialogFormVisible = true
            this.form.name = row.name
            this.form.id = row.id

        },
        handleDelete(index, row) {
            this.open(row.id)
        },
        handleCurrentChange(currentindex) {
            this.showTableData = this.tableData.slice((currentindex - 1) * this.numberPerPage, currentindex * this.numberPerPage)
        },
        open(id) {
            this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deUser(id).then((data) => {
                    if (data.data.status == 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.refresh()
                    } else {
                        alert('操作失败')
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        open2() {
            this.$message({
                message: '提交成功',
                type: 'success'
            });
        },
        handleClose() {
            this.$refs.form.resetFields()
            this.dialogFormVisible = false
        },
        open4(msg) {
            this.$message.error(msg);
        },
        submitForm() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    upUsers(this.form).then((data) => {
                        if (data.data.status == 200) {
                            this.open2()
                            this.dialogFormVisible = false
                            this.refresh()
                        }
                        else {
                            alert('提交失败')
                        }
                    })

                } else {
                    return false;
                }
            });
        },

    },
    mounted() {
        getUsers().then((data) => {
            if (data.data.status == 200) {
                this.tableData = data.data.data
                this.total = data.data.data.length ? data.data.data.length : 0
                this.showTableData = data.data.data.slice(0, this.numberPerPage);
                this.loading = false
            } else {
                this.open4(data.data.msg)
            }
        })
    }
}
</script>
<style lang="less" scoped>
/deep/ .cell {
    height: 40px;
    overflow-y: auto;
}
</style>