<template>
    <div>
        <div class="do-bg" v-if="active == true">
            <el-form :model="doctorUser" ref="doctor" :rules="doctorrules" class="do-login" label-width="60px">
                <div class="pic" style=" background-color: #67C23A;">
                    <img width="100%" class="face" height="100%" src="../assets/医生.png">
                    <el-tooltip class="item" effect="light" content="切换到管理员" placement="top-start">
                        <el-button @click="tab('doctor')" type="warning" class="back" icon="el-icon-d-arrow-right"
                        circle></el-button>
                    </el-tooltip>

       
                </div>

                <el-form-item label="电话" prop="telephone">
                    <el-input :maxlength="11" v-model="doctorUser.telephone"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input :maxlength="20" type="password" v-model="doctorUser.password"></el-input>
                </el-form-item>
                <div class="foot">
                    <el-button type="success" @click="submitDoctor">Login</el-button>
                </div>
            </el-form>
        </div>
        <div class="ad-bg" v-if="active != true">
            <div class="container">
                <div class="bubbles">
                    <span style="--i:11"></span>
                    <span style="--i:12"></span>
                    <span style="--i:24"></span>
                    <span style="--i:10"></span>
                    <span style="--i:14"></span>
                    <span style="--i:23"></span>
                    <span style="--i:18"></span>
                    <span style="--i:16"></span>
                    <span style="--i:19"></span>
                    <span style="--i:20"></span>
                    <span style="--i:22"></span>
                    <span style="--i:25"></span>
                    <span style="--i:18"></span>
                    <span style="--i:21"></span>
                    <span style="--i:15"></span>
                    <span style="--i:13"></span>
                    <span style="--i:26"></span>
                    <span style="--i:17"></span>
                    <span style="--i:13"></span>
                    <span style="--i:28"></span>
                    <span style="--i:29"></span>
                    <span style="--i:35"></span>
                    <span style="--i:42"></span>
                    <span style="--i:40"></span>
                    <span style="--i:38"></span>
                    <span style="--i:36"></span>
                    <span style="--i:41"></span>
                    <span style="--i:37"></span>
                    <span style="--i:39"></span>
                    <span style="--i:34"></span>
                    <span style="--i:30"></span>
                    <span style="--i:8"></span>
                    <span style="--i:25"></span>
                    <span style="--i:14"></span>
                    <span style="--i:28"></span>
                    <span style="--i:36"></span>
                    <span style="--i:17"></span>
                    <span style="--i:23"></span>
                    <span style="--i:19"></span>
                    <span style="--i:38"></span>
                </div>

                <el-form :model="superUser" ref="super" :rules="superrules" class="ad-login" label-width="60px">
                    <div class="pic" style=" background-color: #027cff;">
                        <img width="100%" height="100%" src="../assets/管理员.png">
                        <el-tooltip class="item" effect="light" content="切换到医生" placement="top-start">
                        <el-button @click="tab('super')" type="warning" class="back" icon="el-icon-d-arrow-left"
                        circle></el-button>
                    </el-tooltip>
                    </div>
                    <el-form-item label="账号" prop="account">
                        <el-input :maxlength="10" v-model="superUser.account"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input :maxlength="20" type="password" v-model="superUser.password"></el-input>
                    </el-form-item>
                    <div class="foot">
                        <el-button type="primary" @click="submitSuper">Login</el-button>
                    </div>
                </el-form>




            </div>


        </div>

    </div>
</template>
<script>
import Cookie from 'js-cookie'
import { loginSuper, addAdmin, loginDoctor } from '@/api'

export default {
    data() {
        return {
            active: true,
            doctorUser: {
                telephone: '',
                password: ''
            },
            superUser: {
                account: '',
                password: ''
            },
            doctorrules: {
                telephone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max: 11, message: '手机号长度为11位', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '密码长度在6到20之间', trigger: 'blur' }
                ],


            },

            superrules: {
                id: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                    { max: 10, message: '账号最大长度为10位', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '密码长度在6到20之间', trigger: 'blur' }
                ],
            },

        }
    },
    methods: {
        tab(formname) {
            this.$refs[formname].resetFields()
            this.active = !this.active
        },
        open2() {
            this.$message({
                showClose: true,
                message: '操作成功',
                type: 'success'
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        submitSuper() {
            this.$refs.super.validate((valid) => {
                if (valid) {
                    loginSuper(this.superUser).then((data) => {
                        if (data.data.status == 200) {
                            Cookie.set('token', data.data.token)
                            Cookie.set('admin', JSON.stringify(data.data.admin))
                            Cookie.set('identity',data.data.identity)
                            this.$store.commit('setMenu', data.data.supermenu)
                            this.$store.commit('addMenu', this.$router)
                            this.$router.push('/homePage')
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: data.data.msg,
                                type: 'error'
                            });

                        }
                    })
                } else {
                    console.log('error submit!!');

                    return false;
                }
            });
        },
        submitDoctor() {
            this.$refs.doctor.validate((valid) => {
                if (valid) {
                    loginDoctor(this.doctorUser).then((data) => {
                        if (data.data.status == 200) {
                            Cookie.set('token', data.data.token)
                            Cookie.set('doctor', JSON.stringify(data.data.doctor))
                            Cookie.set('identity', data.data.identity)
                            Cookie.set('department',data.data.department)
                            this.$store.commit('setMenu', data.data.doctormenu)
                            this.$store.commit('addMenu', this.$router)
                            this.$router.push('/clockIn')
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: data.data.msg,
                                type: 'error'
                            });

                        }
                    })
                } else {
                    console.log('error submit!!');

                    return false;
                }
            });
        },
    }
} 
</script>
<style lang="less" scoped>
@keyframes bgAnimation{
  0%{
    background-position:0 50% ;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0 50%;
  }
}
@keyframes animate {
    0% {
        transform: translateY(100vh) scale(0);
    }

    100% {
        transform: translateY(-10vh) scale(1);
    }

}

.do-bg {
    display: flex;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: linear-gradient(125deg, #2c3e50, #27ae60,#8e44ad,#2980b9,#e74c3c);
  animation: bgAnimation 15s linear infinite; 
  background-size: 500%;

}

.ad-bg {
    height: 100vh;
    width: 100%;
    background-color: #0c192c;

    .container {
        position: relative;
        overflow: hidden;
        height: 100%;
        width: 100%;

        .bubbles {
            display: flex;
            position: relative;

            span {
                position: relative;
                width: 30px;
                height: 30px;
                background: #4fc3dc;
                margin: 0 4px;
                border-radius: 50%;
                box-shadow: 0 0 0 10px #4fc3dc44,
                    0 0 50px #4fc3dc,
                    0 0 100px #4fc3dc;
                animation: animate 15s linear infinite;
                animation-duration: calc(125s / var(--i));
            }



        }
    }

}



.do-login {

    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 300px;
    width: 300px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #f8f8f8;
    border-radius: 10px 10px 10px 10px;
}

.ad-login {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -150px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 300px;
    width: 300px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #f8f8f8;
    border-radius: 10px 10px 10px 10px;
}

.foot {
    p {

        font-size: 8px;
        color: #409EFF;
    }

    p:hover {
        text-decoration: underline;
        cursor: default
    }

    width: 75%;
    display: flex;
    flex-direction: column;

}

.pic {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -25px;
    border-radius: 50%;
    padding: 5px;
    transform-style: preserve-3d;
    transition: all .4s;

    .face {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
    }

    .back {
        position: absolute;
        transform: translateY(30px) rotateX(-90deg);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

    }
}

.pic:hover {
    transform: rotateX(90deg);
}
</style>