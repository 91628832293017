<template>

<router-view v-if="isRresh" ></router-view>
</template>

<script>
import router from './router';

export default {
    name: "App",
    components: { router },
    data:()=>({
      isRresh: true
    }),
    provide() {
    return {
      refresh: this.refresh
    }
  },
  methods: {
    refresh() {
      this.isRresh = false;
      this.$nextTick(() => {
        this.isRresh = true;
      })
    }
  }
}
</script>

<style lang="less">
html,body,.el-header{
  margin: 0;
  padding: 0;

}
</style>
