<template>
  <div  v-loading="loading" style="background-color: #ffffff;padding-left: 20px" element-loading-text="拼命加载中">


    <el-tabs>
      <el-tab-pane label="现有医生">



        <div :class="tab == 0 ? 'show' : 'hidden'" class="list">
          <div>
            <el-select v-model="value" filterable placeholder="请选择" clearable>
              <el-option v-for="item in positions" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>

          <div class="doctorlist">
            <el-card shadow="hover" v-for="item in select" :body-style="{ padding: '0px' }">
              <el-image fit="cover" :src="imgurl + item.face" class="image"></el-image>
              <div class="card-foot">
                <div class="card-foot-top">
                  <span>{{ item.name }}</span>
                  <span>{{ item.position }}</span>
                </div>
                <div class="bottom clearfix">
                  <div class="information">{{ item.information }}</div>
                  <el-dropdown>
                    <el-button class="button" size="mini">操作</el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <div @click="details(item)">详情</div>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <div @click="handledelete(item.id)">删除</div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>

            </el-card>
          </div>


        </div>

        <div :class="tab == 1 ? 'show' : 'hidden'">
          <el-row>
            <el-col :span="4">
              <div class="face">
                <el-image fit="fill" :src="imgurl + doctor.face" class="image" />
                <el-upload :show-file-list="false" class="upload-demo"
                  :action="headurl + '/update_doctor/?id=' + doctor.id" multiple :on-success="updateFace" :limit="1"
                  :on-exceed="handleExceed">
                  <div><el-button size="small" type="primary">修改头像</el-button></div>
                </el-upload>
              </div>
            </el-col>
            <el-col :span="20"> <el-descriptions title="详细信息">
                <el-descriptions-item label="姓名">{{ doctor.name }}</el-descriptions-item>
                <el-descriptions-item label="手机号">{{ doctor.telephone }}</el-descriptions-item>
                <el-descriptions-item label="职称">{{ doctor.position }}</el-descriptions-item>
                <el-descriptions-item label="科室">{{ doctor.department }}</el-descriptions-item>
                <el-descriptions-item label="介绍">{{ doctor.information }}</el-descriptions-item>
              </el-descriptions>
              <div class="foot">
                <el-button @click="handleedit(doctor)" type="primary">编辑</el-button>
                <el-button type="primary" @click="back" icon="el-icon-arrow-left">上一页</el-button>
              </div>
            </el-col>
          </el-row>
        </div>

        <div :class="tab == 2 ? 'show' : 'hidden'">
          <el-form ref="form" :rules="rule4" :model="doctor" label-width="80px">
            <el-form-item label="姓名">
              <el-input :maxlength="10" disabled v-model="doctor.name"></el-input>
            </el-form-item>

            <el-form-item label="科室">
              <el-input disabled v-model="doctor.department"></el-input>
            </el-form-item>
            <el-form-item label="账号密码" prop="password">
              <el-input :maxlength="20" show-word-limit v-model="doctor.password"></el-input>
            </el-form-item>
            <el-form-item label="职称" prop="position">
              <el-select v-model="doctor.position">
                <el-option v-for="(item, index) in positions" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="电话" prop="telephone">
              <el-input :maxlength="11" show-word-limit v-model="doctor.telephone"></el-input>
            </el-form-item>
            <el-form-item label="个人介绍" autosize prop="information">
              <el-input :maxlength="100" show-word-limit type="textarea" v-model="doctor.information"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="editSumbit" type="primary">立即修改</el-button>
              <el-button @click="back">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane label="添加医生">
        <el-steps simple :active="active" finish-status="success">
          <el-step title="创建账号"></el-step>
          <el-step title="个人信息"></el-step>
          <el-step title="上传照片"></el-step>
        </el-steps>
        <div :class="active == 0 ? 'evpage' : 'hidden'">
          <el-form ref="form0" :rules="rules1" :model="form0" label-width="80px">
            <el-form-item label="手机号" prop="telephone">
              <el-input :maxlength="11" show-word-limit placeholder="请输入手机号" v-model="form0.telephone"
                autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="pass">
              <el-input :maxlength="20" placeholder="请输入密码" show-word-limit type="password" v-model="form0.pass"
                autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input :maxlength="20" placeholder="请确认密码" show-word-limit type="password" v-model="form0.checkPass"
                autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div :class="active == 1 ? 'evpage' : 'hidden'">
          <el-form ref="form1" :rules="rules2" :model="form1" label-width="80px">
            <el-form-item label="姓名" prop="name">
              <el-input :maxlength="10" placeholder="请输入姓名" show-word-limit v-model="form1.name"
                autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="科室" prop="department">
              <el-select v-model="form1.department" placeholder="请选择科室">
                <el-option v-for="(item, index) in department" :label="item.name" :value="item.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职称" prop="position">
              <el-select v-model="form1.position" placeholder="请选择职称">
                <el-option v-for="(item, index) in positions" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="个人介绍" autosize prop="information">
              <el-input :maxlength="100" show-word-limit type="textarea" v-model="form1.information"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div :class="active == 2 ? 'evpage' : 'hidden'">
          <el-form ref="form2" :rules="rules3" :model="form2" label-width="80px">
            <el-form-item label="个人图片" prop="fileList">
              <el-upload ref="upload" class="avatar-uploader" action="" :limit="1" :http-request="httpRequest"
                accept=".jpg,.png" :multiple="false" :auto-upload="false" list-type="picture">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>


        <div class="foot">
          <el-button style="margin-top: 12px;" @click="previous">上一步</el-button>
          <el-button style="margin-top: 12px;" @click="next('form' + active)">下一步</el-button>
        </div>

      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { addDoctor, getDoctors, delete_doctor, update_doctor, Department } from '@/api'
import { URL } from '@/util/request'

export default {
  inject: ["refresh"],
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form0.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      like: true,
      headurl: URL,
      selectres: [],
      selectName: '',
      tab: 0,
      imgurl: URL + 'media/',
      doctor: {},
      doctorList: [],
      loading: true,
      active: 0,
      form: {
        name: '',
        position: '',
        department: '',
        information: '',
        pass: '',
        telephone: '',
        resource: '',
        desc: ''
      },
      department: [],
      positions: ['主任医师', '副主任医师', '住院医师', '医师', '助理医师'],
      imageUrl: '',
      form2: {
        face: '',
      },
      form0: {
        telephone: '',
        pass: '',
        checkPass: '',
      },
      form1: {
        name: '',
        position: '',
        department: '',
        information: '',
      }, rules1: {
        telephone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '手机号长度为11位', trigger: 'blur' }
        ],
        pass: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在6到20之间', trigger: 'blur' }
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在6到20之间', trigger: 'blur' }
        ],
      },
      rules2: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '姓名最大长度为10位', trigger: 'blur' }
        ],
        position: [
          { required: true, message: '请选择职称', trigger: 'change' },
        ],
        department: [
          { required: true, message: '请选择科室', trigger: 'change' },
        ],
        information: [
          { required: true, message: '请输入个人描述', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
        ],
      },
      rules3: {
        face: [
          { required: true, message: '请上传照片', trigger: 'change' },
        ],

      },
      rule4: {
        telephone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '手机号长度为11位', trigger: 'blur' }
        ],
        position: [
          { required: true, message: '请选择职称', trigger: 'change' },
        ],
        information: [
          { required: true, message: '请输入个人描述', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在6到20之间', trigger: 'blur' }
        ],

      },
      value: ''
    };
  },
  computed: {
    select(key) {
      if (this.value == '')
        return this.doctorList
      else
        return this.doctorList.filter(x => x.position == this.value)
    },

  },

  methods: {
    //data是要被分组的数据[]，key是分组依据的关键字
    getGroup(data, key) {
      console.log(data);
      let groups = {};
      data.forEach(c => {
        let value = c[key];
        groups[value] = groups[value] || [];
        groups[value].push(c);
      });
      console.log(groups);
    },

    //分组
    next(formName) {
      this.submitForm(formName)
    },
    open4(msg) {
      this.$message.error(msg);
    },
    submitForm(formName) {
      if (this.active == 2)
        this.$refs.upload.submit()
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.active++ > 1) {
            this.active = 2
            const formData = new FormData();
            for (let key in this.form0) {
              formData.append(key, this.form0[key])
            }
            for (let key in this.form1) {
              formData.append(key, this.form1[key])
            }
            for (let key in this.form2) {
              formData.append(key, this.form2[key])
            }
            addDoctor(formData).then((data) => {
              if (data.data.status == 200) {
                this.open2()
                this.$refs.form0.resetFields()
                this.$refs.form1.resetFields()
                this.$refs.form2.resetFields()
                this.refresh()
              } else {
                this.open4(data.data.msg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },

    httpRequest(param) {
      this.form2.face = param.file;
    },

    open2() {
      this.$message({
        message: '操作成功',
        type: 'success'
      });
    },

    previous() {
      if (this.active-- < 1) this.active = 0;
    },

    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，
      共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    handledelete(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delete_doctor(id).then((data) => {
          if (data.data.status == 200) {
            this.open2()
            this.refresh()
          } else {
            this.open4(data.data.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    updateFace() {
      this.open2()
      this.refresh()
    },

    handleedit(item) {
      this.details(item)
    },
    details(item) {
      if (++this.tab > 2) {
        this.tab = 2
      }
      this.doctor = item

    },
    back() {
      if (--this.tab < 0) {
        this.tab = 0
      }
    },

    handleSelect(item) {
      console.log(item);
    },
    editSumbit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          update_doctor(this.doctor).then((data) => {
            if (data.data.status == 200) {
              this.open2()
              getDoctors().then((data) => {
                if (data.data.status == 200) {
                  this.doctorList = data.data.data
                } else {
                  this.open4(data.data.msg)
                }
              })
            }
            else {
              this.open4(data.data.msg)
            }

          })
        } else {
          return false;
        }
      });
    }
  },
  mounted() {
    getDoctors().then((data) => {
      this.doctorList = data.data.data
      this.getGroup(this.doctorList, 'position')
      this.loading = false
    })
    Department().then((data) => {
      this.department = data.data.data
    })



  }
}



</script>
<style lang="less" scoped>
.el-tabs--border-card {
  background: none;
  border: none;
  box-shadow: none;
}

.doctorlist {
  display: flex;
  flex-wrap: wrap;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: auto;

  .card-foot {
    padding: 14px;
    border-top: 1px solid #efefef;
    background: linear-gradient(220.55deg, #7CF7FF 0%, #4B73FF 100%);

    .bottom {
      margin-top: 13px;
      line-height: 12px;
      display: flex;
      justify-content: space-around;

      .information {
        font-size: 13px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 60%;
        /* 可以显示的行数，超出部分用...表示*/
        -webkit-box-orient: vertical;
        /*控制显示行数*/
        -webkit-line-clamp: 1;
      }

      .el-dropdown {
        float: right;
      }
    }

    .card-foot-top {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;

    }
  }
}

.show {
  display: block;
}

.hidden {
  display: none;

}

.el-form {
  margin-top: 20px;
}

.el-card {
  width: 20%;
  // height: 300px;
  margin: 10px 20px;

  .el-image {
    display: block;
  }

}

.evpage {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-self: center;

}

.foot {
  float: right;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.face {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .image {
    width: 100%;
    height: 100%;
    // display: block;
  }

}


.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}



.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  padding: 0 2px;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.back {
  float: right;
}

/deep/.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.like {
  cursor: pointer;
  font-size: 25px;
  display: inline-block;
}
</style>