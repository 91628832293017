import Vue from 'vue'
import App from './App.vue'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import ElementUI from 'element-ui';
import store from '../src/store'
import Cookies from 'js-cookie'
import weather from 'vue-mini-weather'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'


Vue.config.devtools = true
Vue.prototype.$VXETable = VXETable
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(weather);

Vue.use(VXETable)
// Vue.use(VueAMap)
//添加前置导航首位
router.beforeEach((to, from, next) => {
  const token = Cookies.get('token')
  if (!token && to.name != 'login') {
    next({ name: 'login' })
  }
  else if (token && to.name == 'login') {
    next({ name: 'homePage' })

  } else
    next()

})


Vue.prototype.$globalVariable = {
  website: "www."

}

// 初始化vue-amap
//高德的安全密钥
window._AMapSecurityConfig = {
	securityJsCode:'a6cd3ae5d37007a0b38576399c9fbe9a',
}



new Vue({
  router,
  store,
  created() {
    this.$store.commit('addMenu', router)
  },
  render: h => h(App),
}).$mount('#app')
