import Cookie from "js-cookie"

export default{
    state:{
        tabList:[
        ],
    },
    mutations:{
        selectMenu(state,e){
            if(e.name != 'homePage'){
                const index = state.tabList.findIndex(item=> item.name == e.name)
                if(index == -1)
                state.tabList.push(e)
            }
    

        },
        setMenu(state,val){
            state.menu = val
            Cookie.set('menu',JSON.stringify(val))
        },
        //动态添加路由
        addMenu(state,router){
            if(!Cookie.get('menu')) return
            const menu =  JSON.parse(Cookie.get('menu'))
            state.menu = menu
            const Menulist = []
            menu.forEach(item => {
                if (item.children) {
                    item.children = item.children.map(item => {
                        item.component=()=>import(`../views/${item.url}`)
                        return item
                    })
                    Menulist.push(...item.children)
                }
                else {
                    item.component=()=>import(`../views/${item.url}`)
                    Menulist.push(item)
                }
                
            });
            Menulist.forEach(item =>{
                router.addRoute('Main',item)
            })
        }

    }
}