import Vue from 'vue'
import VueRouter from 'vue-router'
import orderForm from '../views/orderForm.vue'
import Main from '../views/Main.vue'
import mall from '../views/mall.vue'
import notice from '../views/notice.vue'
import user from '../views/user.vue'
import doctor from '../views/doctor.vue'
import login from '../views/login.vue'

Vue.use(VueRouter)


const routes = [{
  path: '/',
  component: Main,
  name:'Main',
  redirect: '/homePage',
  children: [

  ]
},{
  path:'/login',
  name:'login',
  component: login
}]


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})




export default router
