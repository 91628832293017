
import http from '../util/request'
export const getDataO = () => {
    return http.get('/get_order/')
}

export const upOrder = (e) => {
    return http.post('/update_order/', { 'form': e }, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })

}
export const deOrder = (e) => {
    return http.get('/delete_order/', { params: { 'id': e } })
}

export const addGoods = (e) => {
    return http.post('/add_medicine/', e, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getDataG = () => {
    return http.get('/getMedicine/')
}


export const upGoods = (e) => {
    return http.post('/update_medicine/', e, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}


export const deGoods = (e) => {
    return http.get('/delete_medicine/', { params: { 'id': e } }, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getNotice = () => {
    return http.get('/get_notice/')
}

export const addNotice = (e) => {
    return http.post('/add_notice/', e, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const deNotice = (e) => {
    return http.get('/delete_notice/', { params: { 'id': e } }, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getUsers = () => {
    return http.get('/getUsers/')
}

export const upUsers = (e) => {
    return http.post('/admin_upuser/', e, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}


export const deUser = (e) => {
    return http.get('/admin_deuser/', { params: { 'id': e } }, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const addDoctor=(e)=>{
    return http.post('/add_doctor/',e,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getDoctors = () => {
    return http.get('/get_doctors/')
}

export const delete_doctor = (e) => {
    return http.get('/delete_doctor/', { params: { 'id': e } }, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const update_doctor = (e) => {
    return http.post('/update_doctor/', e, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
export const loginSuper=(e)=>{
    return http.post('/admin_login/',e,{
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    })
}


export const addAdmin = (e) => {
    return http.post('/addAdmin/', e, {
        headers: {
            'Content-Type': 'multipart/form-data'}
        
    })
}

export const loginDoctor=(e)=>{
    return http.post('/doctor_login/',e,{
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getLetters = (e) => {
    return http.get('/getLetters_by_doctor_id/',{ params: { 'id': e } }, {
        headers: {
            'Content-Type': 'multipart/form-data'}
        
    })
}


export const getAppointment = (e) => {
    return http.get('/getAppointment_by_doctorid/',{ params: { 'id': e } }, {
        headers: {
            'Content-Type': 'multipart/form-data'}
        
    })
}

export const addPush = (e) => {
    return http.post('/add_push/', e, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getPush = () => {
    return http.get('/get_push/', {
        headers: {
            'Content-Type': 'multipart/form-data'}
        
    })
}

export const dePush = (e) => {
    return http.get('/delete_push/', { params: { 'id': e } }, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}




export const getProfession = () => {
    return http.get('/profession/', {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const Allgoods = () => {
    return http.get('/select_medicine_by_type/', {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const deAppoint = (e) => {
    return http.get('/delete_appointment/', { params: { 'id': e,'from':'doctor' } }, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getType = () => {
    return http.get('/getType', {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getMymessages = (e) => {
    return http.get('/get_doctorMessage/',{ params: { 'name': e } }, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const Department = () => {
    return http.get('/department/', {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const add_department = (e) => {
    return http.post('/add_department/',e, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const update_department = (e) => {
    return http.post('/update_department/',e, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}


export const delete_department = (e) => {
    return http.get('/delete_department/', { params: { 'id': e } }, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const chat = (e) => {
    return http.get('/chat/', { params: { 'userId': e.userId,'doctorId':e.doctorId} }, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const get_all_answer = () => {
    return http.get('/get_all_answer/', {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const add_answer=(e)=>{
    return http.post('/add_answer/',e,{
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const update_answer=(e)=>{
    return http.post('/update_answer/',e,{
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    })
}


export const delete_answer=(e)=>{
    return http.post('/delete_answer/',{params: { 'id': e }},{
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const update_appointment=(e)=>{
    return http.post('/update_appointment/',e,{
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const editNotice = (e) => {
    return http.post('/editNotice/', e, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const addSchedule = (e) => {
    return http.post('/addSchedule/', e, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}


export const getSchedule = () => {
    return http.get('/getSchedule/', {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const addDrugType = (e) => {
    return http.post('/addDrugType/',e, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}


export const deleteDrugType = (e) => {
    return http.get('/deleteDrugType/',{params: { 'id': e }}, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
    

export const clockIn=(e)=>{
    return http.get('/clockIn/',{params: { 'id': e.id,'type':e.type }},{
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    })
}