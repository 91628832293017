<template>
  <div>
    <el-table size="small" stripe height="580px" v-loading="loading" element-loading-text="拼命加载中" :data="search ? tableData.filter(data => !search || data.buyername.toLowerCase().includes(search.toLowerCase())) :
      showTableData" style="width: 100%">
      <el-table-column label="Id" prop="id">
      </el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <el-popover placement="top-start" title="" trigger="hover">
            <img :src="imgurl + scope.row.goodsimgurl" alt="" style="width: 150px;height: 150px">
            <img slot="reference" :src="imgurl + scope.row.goodsimgurl" style="width: 50px;height: 30px">
          </el-popover>
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="goodname">
      </el-table-column>
      <el-table-column label="数量" prop="num">
      </el-table-column>
      <el-table-column label="单价" prop="price">
      </el-table-column>
      <el-table-column label="消费者" prop="buyername">
      </el-table-column>
      <el-table-column label="提交时间" prop="detetime">
      </el-table-column>
      <el-table-column align="right">
        <template slot="header" slot-scope="scope">
          <el-input v-model="search" size="mini" placeholder="输入消费者搜索" />
        </template>
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>



    <el-dialog width="300px" :modal-append-to-body=false :append-to-body=true title="编辑订单"
      :visible.sync="dialogFormVisible">
      <el-form :model="form" label-width="60px">
        <el-form-item label="数量">
          <el-input v-model="form.num" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="单价">
          <el-input v-model="form.price" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="消费者">
          <el-input v-model="form.buyerName" autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </div>
    </el-dialog>


    <div v-if="!search" class="block" style="display: flex;justify-content: center;">
      <el-pagination @current-change="handleCurrentChange" :page-size="numberPerPage" layout="prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getDataO, upOrder, deOrder } from '@/api';
import { URL } from '@/util/request'
import moment from "moment"
export default {
  data() {
    return {
      imgurl: URL+'media/',
      loading: true,
      showTableData: [],
      numberPerPage: 10,
      tableData: [],
      search: '',
      total: 0,
      dialogFormVisible: false,
      form: {
        buyerName: '',
        num: '',
        price: '',
        id: ''
      },
      formLabelWidth: '120px'
    }
  },
  inject: ["refresh"],
  methods: {
    handleEdit(index, row) {
      this.dialogFormVisible = true;
      this.form.num = row.num
      this.form.buyerName = row.buyername
      this.form.price = row.price
      this.form.id = row.id

    },
    handleDelete(index, row) {
      this.open(row)
    },

    open(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        deOrder(row.id).then((data) => {
          if (data.data.status == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })

          } else {
            this.open4(data.data.msg)
          }
        })
        this.refresh()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    open2() {
      this.$message({
        message: '编辑成功',
        type: 'success'
      });
    },
    open4(msg) {
      this.$message.error(msg);
    },

    onSubmit() {
      this.open2()
      this.dialogFormVisible = false
      upOrder(this.form).then((data) => {
        if (data.data.status == 200) {
          this.open2()
        } else {
          this.open4(data.data.msg)
        }
      })
      this.refresh();
    },

    handleCurrentChange(currentindex) {
      this.showTableData = this.tableData.slice((currentindex - 1) * this.numberPerPage, currentindex * this.numberPerPage)
    },


  },


  mounted() {
    getDataO().then((data) => {
      if (data.data.status == 200) {
        this.tableData = data.data.data
        this.total = data.data.data.length ? data.data.data.length : 0
        this.showTableData = data.data.data.slice(0, this.numberPerPage);
        this.loading = false
      } else {
        this.open4(data.data.msg)
      }
    })
  }
}


</script>
<style lang="less" scoped ></style>